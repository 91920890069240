import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="traps"></a><h2>Traps</h2>
    <a id="types-of-traps"></a><p className="initial"><b>Types of Traps</b>: A trap can be either
mechanical or magic in nature. Mechanical traps include pits, arrow
traps, falling blocks, water-filled rooms, whirling blades, and
anything else that depends on a mechanism to operate. A mechanical trap
can be constructed by a PC through successful use of the <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(trapmaking) skill (see Designing a Trap, below, and the skill
description).</p>
    <p>Magic traps are further divided into spell traps and magic
device traps. Magic device traps initiate spell effects when activated,
just as wands, rods, rings, and other magic items do. Creating a magic
device trap requires the <a href="featsAll.html#craft-wondrous-item" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft Wondrous Item</a> feat (see
Designing a Trap
and the feat description).</p>
    <p>Spell traps are simply spells that themselves function as
traps. Creating a spell trap requires the services of a character who
can cast the needed spell or spells, who is usually either the
character creating the trap or an NPC spellcaster hired for the purpose.</p>
    <a id="mechanical-traps"></a><h5>MECHANICAL TRAPS</h5>
    <p className="initial">Dungeons are frequently equipped with deadly
mechanical (nonmagical) traps. A trap typically is defined by its
location and triggering conditions, how hard it is to spot before it
goes off, how much damage it deals, and whether or not the heroes
receive a saving throw to mitigate its effects. Traps that attack with
arrows, sweeping blades, and other types of weaponry make normal attack
rolls, with a specific attack bonus dictated by the trap&#8217;s design.</p>
    <p>Creatures who succeed on a DC 20 <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
check detect a simple
mechanical trap before it is triggered. (A simple trap is a snare, a
trap triggered by a tripwire, or a large trap such as a pit.)</p>
    <p>A character with the trap sense class feature who succeeds on
a DC 21 (or higher) Search check detects a well-hidden or complex
mechanical trap before it is triggered. Complex traps are denoted by
their triggering mechanisms and involve pressure plates, mechanisms
linked to doors, changes in weight, disturbances in the air,
vibrations, and other sorts of unusual triggers.</p>
    <a id="magic-traps"></a><h5>MAGIC TRAPS</h5>
    <p className="initial">Many spells can be used to create dangerous
traps. Unless the spell or item description states otherwise, assume
the following to be true.</p>
    <ul>
      <li>
        <p>A successful <a href="skillsAll.html#search" style={{
            "color": "rgb(87, 158, 182)"
          }}>Search</a> check (DC 25 + spell
level) made by a
rogue (and only a rogue) detects a magic trap before it goes off. Other
characters have no chance to find a magic trap with a Search check.</p>
      </li>
      <li>
        <p>Magic traps permit a saving throw in order to avoid the
effect (DC 10 + spell level x 1.5).</p>
      </li>
      <li>
        <p>Magic traps may be disarmed by a rogue (and only a rogue)
with a successful <a href="skillsAll.html#disable-device" style={{
            "color": "rgb(87, 158, 182)"
          }}>Disable Device</a> check (DC 25 +
spell level).</p>
      </li>
    </ul>
    <a id="elements-of-a-trap"></a><h5>ELEMENTS OF A TRAP</h5>
    <p className="initial">All traps&#8212;mechanical or magic&#8212;have the
following elements: trigger, reset, <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> DC, <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable
Device</a> DC,
attack bonus (or saving throw or onset delay), damage/effect, and
Challenge Rating. Some traps may also include optional elements, such
as poison or a bypass. These characteristics are described below.</p>
    <a id="trap-trigger"></a><h6>Trigger</h6>
    <p className="initial">A trap&#8217;s trigger determines how it is sprung.</p>
    <a id="trigger-location"></a><p><b>Location</b>: A location trigger springs a trap when
someone stands in a particular square.</p>
    <a id="trigger-proximity"></a><p><b>Proximity</b>: This trigger activates the trap when a
creature approaches within a certain distance of it. A proximity
trigger differs from a location trigger in that the creature need not
be standing in a particular square. Creatures that are flying can
spring a trap with a proximity trigger but not one with a location
trigger. Mechanical proximity triggers are extremely sensitive to the
slightest change in the air. This makes them useful only in places such
as crypts, where the air is unusually still.</p>
    <p>The proximity trigger used most often for magic device traps
is the alarm spell. Unlike when the spell is cast, an alarm spell used
as a trigger can have an area that&#8217;s no larger than the area the trap
is meant to protect.</p>
    <p>Some magic device traps have special proximity triggers that
activate only when certain kinds of creatures approach. For example, a
detect good spell can serve as a proximity trigger on an evil altar,
springing the attached trap only when someone of good alignment gets
close enough to it.</p>
    <a id="trigger-sound"></a><p><b>Sound</b>: This trigger springs a magic trap when it
detects any sound. A sound trigger functions like an ear and has a +15
bonus on <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> checks. A successful <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> check, magical
silence, and other effects that would negate hearing defeat it. A trap
with a sound trigger requires the casting of clairaudience during its
construction.</p>
    <a id="trigger-visual"></a><p><b>Visual</b>: This trigger for magic traps works like an
actual eye, springing the trap whenever it &#8220;sees&#8221; something. A trap
with a visual trigger requires the casting of arcane eye, clairvoyance,
or true seeing during its construction. Sight range and the <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
bonus
conferred on the trap depend on the spell chosen, as shown.</p>
    <a id="table-trap-sight-range"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "450px"
    }}>
      <tbody>
        <tr>
          <th>Spell</th>
          <th>Sight Range</th>
          <th>Spot Bonus</th>
        </tr>
        <tr className="odd-row">
          <td>arcane eye</td>
          <td>Line of sight (unlimited range)</td>
          <td>+20</td>
        </tr>
        <tr>
          <td>clairvoyance</td>
          <td>One preselected location</td>
          <td>+15</td>
        </tr>
        <tr className="odd-row">
          <td>true seeing</td>
          <td>Line of sight (up to 120 ft.)</td>
          <td>+30</td>
        </tr>
      </tbody>
    </table>
    <p>If you want the trap to &#8220;see&#8221; in the dark, you must either
choose the true seeing option or add darkvision to the trap as well.
(Darkvision limits the trap&#8217;s sight range in the dark to 60 feet.) If
invisibility, disguises, or illusions can fool the spell being used,
they can fool the visual trigger as well. </p>
    <a id="trigger-touch"></a><p><b>Touch</b>: A touch trigger, which springs the trap when
touched, is one of the simplest kinds of trigger to construct. This
trigger may be physically attached to the part of the mechanism that
deals the damage or it may not. You can make a magic touch trigger by
adding alarm to the trap and reducing the area of the effect to cover
only the trigger spot.</p>
    <a id="trigger-timed"></a><p><b>Timed</b>: This trigger periodically springs the trap after
a certain duration has passed.</p>
    <a id="trigger-spell"></a><p><b>Spell</b>: All spell traps have this kind of trigger. The
appropriate spell descriptions explain the trigger conditions for traps
that contain spell triggers.</p>
    <a id="trap-reset"></a><h6>Reset</h6>
    <p className="initial">A reset element is the set of conditions under
which a trap becomes ready to trigger again.</p>
    <a id="no-reset"></a><p><b>No Reset</b>: Short of completely rebuilding the trap,
there&#8217;s no way to trigger it more than once. Spell traps have no reset
element. </p>
    <a id="reset-repair"></a><p><b>Repair</b>: To get the trap functioning again, you must
repair it. </p>
    <a id="reset-manual"></a><p><b>Manual</b>: Resetting the trap requires someone to move the
parts back into place. This is the kind of reset element most
mechanical traps have.</p>
    <a id="reset-automatic"></a><p><b>Automatic</b>: The trap resets itself, either immediately
or after a timed interval.</p>
    <a id="repairing-and-resetting-a-mechanical-trap"></a><h6>Repairing and Resetting Mechanical Traps</h6>
    <p className="initial">Repairing a mechanical trap requires a <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(trapmaking) check against a DC equal to the one for building it. The
cost for raw materials is one-fifth of the trap&#8217;s original market
price. To calculate how long it takes to fix a trap, use the same
calculations you would for building it, but use the cost of the raw
materials required for repair in place of the market price.</p>
    <p>Resetting a trap usually takes only a minute or so. For a trap
with a more difficult reset method, you should set the time and labor
required.</p>
    <a id="trap-bypass-optional-element"></a><h6>Bypass (Optional Element)</h6>
    <p className="initial">If the builder of a trap wants to be able to
move past the trap after it is created or placed, it&#8217;s a good idea to
build in a bypass mechanism &#8212;something that temporarily disarms the
trap. Bypass elements are typically used only with mechanical traps;
spell traps usually have built-in allowances for the caster to bypass
them.</p>
    <a id="bypass-lock"></a><p><b>Lock</b>: A lock bypass requires a DC 30 <a href="skillsAll.html#open-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>Open
Lock</a> check to
open. </p>
    <a id="bypass-hidden-switch"></a><p><b>Hidden Switch</b>: A hidden switch requires a DC 25 <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
check to locate.</p>
    <a id="bypass-hidden-lock"></a><p><b>Hidden Lock</b>: A hidden lock combines the features above,
requiring a DC 25 Search check to locate and a DC 30 Open Lock check to
open.</p>
    <a id="search-and-disable-device-dcs"></a><h6>Search and Disable Device DCs</h6>
    <p className="initial">The builder sets the <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
and <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable Device</a>
DCs for a mechanical trap. For a magic trap, the values depend on the
highest-level spell used.</p>
    <a id="mechanical-trap"></a><p><b>Mechanical Trap</b>: The base DC for both Search and
Disable Device checks is 20. Raising or lowering either of these DCs
affects the base cost (Table: Cost Modifiers for Mechanical Traps) and
possibly the CR (Table: CR Modifiers for Mechanical Traps). </p>
    <a id="magic-trap"></a><p><b>Magic Trap</b>: The DC for both Search and Disable Device
checks is equal to 25 + the spell level of the highest-level spell
used. Only characters with the trap sense class feature can attempt a
Search check or a Disable Device check involving a magic trap. These
DCs do not affect the trap&#8217;s cost or CR.</p>
    <a id="attack-bonus-saving-throw"></a><h6>Attack Bonus/Saving Throw DC</h6>
    <p className="initial">A trap usually either makes an attack roll or
forces a saving throw to avoid it. Occasionally a trap uses both of
these options, or neither (see Never Miss).</p>
    <a id="pits"></a><p><b>Pits</b>: These are holes (covered or not) that characters
can fall into and take damage. A pit needs no attack roll, but a
successful Reflex save (DC set by the builder) avoids it. Other
save-dependent mechanical traps also fall into this category.</p>
    <p><b>Pits in dungeons come in three basic varieties</b>:
uncovered, covered, and chasms. Pits and chasms can be defeated by
judicious application of the <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a> skill, the <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
skill, or
various magical means.</p>
    <p>Uncovered pits serve mainly to discourage intruders from going
a certain way, although they cause much grief to characters who stumble
into them in the dark, and they can greatly complicate a melee taking
place nearby.</p>
    <p>Covered pits are much more dangerous. They can be detected
with a DC 20 <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> check, but only if the
character is taking the time
to carefully examine the area before walking across it. A character who
fails to detect a covered pit is still entitled to a DC 20 Reflex save
to avoid falling into it. However, if she was running or moving
recklessly at the time, she gets no saving throw and falls
automatically.</p>
    <p>Trap coverings can be as simple as piled refuse (straw,
leaves, sticks, garbage), a large rug, or an actual trapdoor concealed
to appear as a normal part of the floor. Such a trapdoor usually swings
open when enough weight (usually about 50 to 80 pounds) is placed upon
it. Devious trap builders sometimes design trapdoors so that they
spring back shut after they open. The trapdoor might lock once it&#8217;s
back in place, leaving the stranded character well and truly trapped.
Opening such a trapdoor is just as difficult as opening a regular door
(assuming the trapped character can reach it), and a DC 13 Strength
check is needed to keep a spring-loaded door open.</p>
    <p>Pit traps often have something nastier than just a hard floor
at the bottom. A trap designer may put spikes, monsters, or a pool of
acid, lava, or even water at the bottom.</p>
    <p>Spikes at the bottom of a pit deal damage as daggers with a
+10 attack bonus and a +1 bonus on damage for every 10 feet of the fall
(to a maximum bonus on damage of +5). If the pit has multiple spikes, a
falling victim is attacked by 1d4 of them. This damage is in addition
to any damage from the fall itself. </p>
    <p>Monsters sometimes live in pits. Any monster that can fit into
the pit might have been placed there by the dungeon&#8217;s designer, or
might simply have fallen in and not been able to climb back out. </p>
    <p>A secondary trap, mechanical or magical, at the bottom of a
pit can be particularly deadly. Activated by a falling victim, the
secondary trap attacks the already injured character when she&#8217;s least
ready for it.</p>
    <a id="ranged-attack-traps"></a><p><b>Ranged Attack Traps</b>: These traps fling darts, arrows,
spears, or the like at whoever activated the trap. The builder sets the
attack bonus. A ranged attack trap can be configured to simulate the
effect of a composite bow with a high strength rating which provides
the trap with a bonus on damage equal to its strength rating.</p>
    <a id="melee-attack-traps"></a><p><b>Melee Attack Traps</b>: These traps feature such obstacles
as sharp blades that emerge from walls and stone blocks that fall from
ceilings. Once again, the builder sets the attack bonus.</p>
    <a id="trap-damage-effect"></a><h6>Damage/Effect</h6>
    <p className="initial">The effect of a trap is what happens to those
who spring it. Usually this takes the form of either damage or a spell
effect, but some traps have special effects.</p>
    <a id="pits-damage"></a><p><b>Pits</b>: Falling into a pit deals 1d6 points of damage per
10 feet of depth. </p>
    <a id="ranged-attack-traps-damage"></a><p><b>Ranged Attack Traps</b>: These traps deal whatever damage
their ammunition normally would. If a trap is constructed with a high
strength rating, it has a corresponding bonus on damage.</p>
    <a id="melee-attack-traps-damage"></a><p><b>Melee Attack Traps</b>: These traps deal the same damage as
the melee weapons they &#8220;wield.&#8221; In the case of a falling stone block,
you can assign any amount of bludgeoning damage you like, but remember
that whoever resets the trap has to lift that stone back into place. </p>
    <p>A melee attack trap can be constructed with a built-in bonus
on damage rolls, just as if the trap itself had a high Strength score. </p>
    <a id="spell-traps-effect"></a><p><b>Spell Traps</b>: Spell traps produce the spell&#8217;s effect.
Like all spells, a spell trap that allows a saving throw has a save DC
of 10 + spell level + caster&#8217;s relevant ability modifier.</p>
    <a id="magic-device-traps-effect"></a><p><b>Magic Device Traps</b>: These traps produce the effects of
any spells included in their construction, as described in the
appropriate entries. If the spell in a magic device trap allows a
saving throw, its save DC is 10 + spell level x 1.5. Some spells make
attack rolls instead.</p>
    <p><b>Special</b>: Some traps have miscellaneous features that
produce special effects, such as drowning for a water trap or <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability
damage</a> for poison. Saving throws and damage depend on the poison or
are
set by the builder, as appropriate.</p>
    <a id="miscellaneous-trap-features"></a><h6>Miscellaneous Trap Features</h6>
    <p className="initial">Some traps include optional features that can
make them considerably more deadly. The most common such features are
discussed below.</p>
    <a id="trap-alchemical-item"></a><p><b>Alchemical Item</b>: Mechanical traps may incorporate
alchemical devices or other special substances or items, such as
tanglefoot bags, alchemist&#8217;s fire, thunderstones, and the like. Some
such items mimic spell effects. If the item mimics a spell effect, it
increases the CR as shown on Table: CR Modifiers for Mechanical Traps.</p>
    <a id="trap-gas"></a><p><b>Gas</b>: With a gas trap, the danger is in the inhaled
poison it delivers. Traps employing gas usually have the never miss and
onset delay features (see below).</p>
    <a id="trap-liquid"></a><p><b>Liquid</b>: Any trap that involves a danger of drowning is
in this category. Traps employing liquid usually have the never miss
and onset delay features (see below). </p>
    <a id="trap-multiple-targets"></a><p><b>Multiple Target</b>: Traps with this feature can affect
more than one character.</p>
    <a id="trap-never-miss"></a><p><b>Never Miss</b>: When the entire dungeon wall moves to crush
you, your quick reflexes won&#8217;t help, since the wall can&#8217;t possibly
miss. A trap with this feature has neither an attack bonus nor a saving
throw to avoid, but it does have an onset delay (see below). Most traps
involving liquid or gas are of the never miss variety. </p>
    <a id="trap-onset-delay"></a><p><b>Onset Delay</b>: An onset delay is the amount of time
between when the trap is sprung and when it deals damage. A never miss
trap always has an onset delay.</p>
    <a id="trap-poison"></a><p><b>Poison</b>: Traps that employ poison are deadlier than
their nonpoisonous counterparts, so they have correspondingly higher
CRs. To determine the CR modifier for a given poison, consult Table: CR
Modifiers for Mechanical Traps. Only injury, contact, and inhaled
poisons are suitable for traps; ingested types are not. Some traps
simply deal the poison&#8217;s damage. Others deal damage with ranged or
melee attacks as well. </p>
    <a id="trap-pit-spikes"></a><p><b>Pit Spikes</b>: Treat spikes at the bottom of a pit as
daggers, each with a +10 attack bonus. The damage bonus for each spike
is +1 per 10 feet of pit depth (to a maximum of +5). Each character who
falls into the pit is attacked by 1d4 spikes. Pit spikes do not add to
the average damage of the trap (see Average Damage, below).</p>
    <a id="trap-pit-bottom"></a><p><b>Pit Bottom</b>: If something other than spikes waits at the
bottom of a pit, it&#8217;s best to treat that as a separate trap (see
Multiple Traps, below) with a location trigger that activates on any
significant impact, such as a falling character. </p>
    <a id="trap-touch-attack"></a><p><b>Touch Attack</b>: This feature applies to any trap that
needs only a successful touch attack (melee or ranged) to hit.</p>
    <a id="sample-traps"></a><h5>SAMPLE TRAPS</h5>
    <p className="initial">The costs listed for mechanical traps are
market prices; those for magic traps are raw material costs. Caster
level and class for the spells used to produce the trap effects are
provided in the entries for magic device traps and spell traps. For all
other spells used (in triggers, for example), the caster level is
assumed to be the minimum required.</p>
    <a id="cr-1-traps"></a><h6>CR 1 Traps</h6>
    <a id="basic-arrow-trap-cr-1"></a><p className="initial"><b>Basic Arrow Trap</b>: CR 1; mechanical;
proximity trigger; manual reset; Atk +10 ranged (1d6/x3, arrow); Search
DC 20; Disable Device DC 20. Market Price: 2,000 gp.</p>
    <a id="camouflaged-pit-trap-cr-1"></a><p><b>Camouflaged Pit Trap</b>: CR 1; mechanical; location
trigger; manual reset; DC 20 Reflex save avoids; 10 ft. deep (1d6,
fall); Search DC 24; Disable Device DC 20. Market Price: 1,800 gp.</p>
    <a id="deeper-pit-trap-cr-1"></a><p><b>Deeper Pit Trap</b>: CR 1; mechanical; location trigger;
manual reset; hidden switch bypass (Search DC 25); DC 15 Reflex save
avoids; 20 ft. deep (2d6, fall); multiple targets (first target in each
of two adjacent 5-ft. squares); Search DC 20; Disable Device DC 23.
Market Price: 1,300 gp.</p>
    <a id="fusillade-of-darts-cr-1"></a><p><b>Fusillade of Darts</b>: CR 1; mechanical; location trigger;
manual reset; Atk +10 ranged (1d4+1, dart); multiple targets (fires 1d4
darts at each target in two adjacent 5-ft. squares); Search DC 14;
Disable Device DC 20. Market Price: 500 gp.</p>
    <a id="poison-dart-trap-cr-1"></a><p><b>Poison Dart Trap</b>: CR 1; mechanical; location trigger;
manual reset; Atk +8 ranged (1d4 plus poison, dart); poison (bloodroot,
DC 12 Fortitude save resists, 0/1d4 Con plus 1d3 Wis); Search DC 20;
Disable Device DC 18. Market Price: 700 gp.</p>
    <a id="poison-needle-trap-cr-1"></a><p><b>Poison Needle Trap</b>: CR 1; mechanical; touch trigger;
manual reset; Atk +8 ranged (1 plus greenblood oil poison); Search DC
22; Disable Device DC 20. Market Price: 1,300 gp.</p>
    <a id="portculllis-trap-cr-1"></a><p><b>Portcullis Trap</b>: CR 1; mechanical; location trigger;
manual reset; Atk +10 melee (3d6); Search DC 20; Disable Device DC 20.
Note: Damage applies only to those underneath the portcullis.
Portcullis blocks passageway. Market Price: 1,400 gp.</p>
    <a id="razor-wire-across-hallway-cr-1"></a><p><b>Razor-Wire across Hallway</b>: CR 1; mechanical; location
trigger; no reset; Atk +10 melee (2d6, wire); multiple targets (first
target in each of two adjacent 5-ft. squares); Search DC 22; Disable
Device DC 15. Market Price: 400 gp.</p>
    <a id="rolling-rock-trap-cr-1"></a><p><b>Rolling Rock Trap</b>: CR 1; mechanical; location trigger;
manual reset; Atk +10 melee (2d6, rock); Search DC 20; Disable Device
DC 22. Market Price: 1,400 gp.</p>
    <a id="scything-blade-trap-cr-1"></a><p><b>Scything Blade Trap</b>: CR 1; mechanical; location
trigger; automatic reset; Atk +8 melee (1d8/x3); Search DC 21; Disable
Device DC 20. Market Price: 1,700 gp.</p>
    <a id="spear-trap-cr-1"></a><p><b>Spear Trap</b>: CR 1; mechanical; location trigger; manual
reset; Atk +12 ranged (1d8/x3, spear); Search DC 20; Disable Device DC
20. Note: 200-ft. max range, target determined randomly from those in
its path. Market Price: 1,200 gp.</p>
    <a id="swinging-block-trap-cr-1"></a><p><b>Swinging Block Trap</b>: CR 1; mechanical; touch trigger;
manual reset; Atk +5 melee (4d6, stone block); Search DC 20; Disable
Device DC 20. Market Price: 500 gp.</p>
    <a id="wall-blade-trap-cr-1"></a><p><b>Wall Blade Trap</b>: CR 1; mechanical; touch trigger;
automatic reset; hidden switch bypass (Search DC 25); Atk +10 melee
(2d4/x4, scythe); Search DC 22; Disable Device DC 22. Market Price:
2,500 gp.</p>
    <a id="cr-2-traps"></a><h6>CR 2 Traps</h6>
    <a id="box-of-brown-mold-cr-2"></a><p className="initial"><b>Box of Brown Mold</b>: CR 2; mechanical;
touch trigger (opening the box); automatic reset; 5-ft. cold aura (3d6,
cold nonlethal); Search DC 22; Disable Device DC 16. Market Price:
3,000 gp.</p>
    <a id="bricks-from-ceiling-cr-2"></a><p><b>Bricks from Ceiling</b>: CR 2; mechanical; touch trigger;
repair reset; Atk +12 melee (2d6, bricks); multiple targets (all
targets in two adjacent 5-ft. squares); Search DC 20; Disable Device DC
20. Market Price: 2,400 gp.</p>
    <a id="burning-hands-trap-cr-2"></a><p><b>Burning Hands Trap</b>: CR 2; magic device; proximity
trigger (alarm); automatic reset; spell effect (burning hands,
1st-level wizard, 1d4 fire, DC 11 Reflex save half damage); Search DC
26; Disable Device DC 26. Cost: 500 gp, 40 XP.</p>
    <a id="camouflaged-pit-trap-cr-2"></a><p><b>Camouflaged Pit Trap</b>: CR 2; mechanical; location
trigger; manual reset; DC 20 Reflex save avoids; 20 ft. deep (2d6,
fall); multiple targets (first target in each of two adjacent 5-ft.
squares); Search DC 24; Disable Device DC 19. Market Price: 3,400 gp.</p>
    <a id="inflict-light-wounds-trap-cr-2"></a><p><b>Inflict Light Wounds Trap</b>: CR 2; magic device; touch
trigger; automatic reset; spell effect (inflict light wounds, 1st-level
cleric, 1d8+1, DC 11 Will save half damage); Search DC 26; Disable
Device DC 26. Cost: 500 gp, 40 XP.</p>
    <a id="javelin-trap-cr-2"></a><p><b>Javelin Trap</b>: CR 2; mechanical; location trigger;
manual reset; Atk +16 ranged (1d6+4, javelin); Search DC 20; Disable
Device DC 18. Market Price: 4,800 gp.</p>
    <a id="large-net-trap-cr-2"></a><p><b>Large Net Trap</b>: CR 2; mechanical; location trigger;
manual reset; Atk +5 melee (see note); Search DC 20; Disable Device DC
25. Note: Characters in 10-ft. square are grappled by net (Str 18) if
they fail a DC 14 Reflex save. Market Price: 3,000 gp.</p>
    <a id="pit-trap-cr-2"></a><p><b>Pit Trap</b>: CR 2; mechanical, location trigger; manual
reset; DC 20 Reflex save avoids; 40 ft. deep (4d6, fall); Search DC 20;
Disable Device DC 20. Market Price: 2,000 gp.</p>
    <a id="poison-needle-trap-cr-2"></a><p><b>Poison Needle Trap</b>: CR 2; mechanical; touch trigger;
repair reset; lock bypass (Open Lock DC 30); Atk +17 melee (1 plus
poison, needle); poison (blue whinnis, DC 14 Fortitude save resists
(poison only), 1 Con/unconsciousness); Search DC 22; Disable Device DC
17. Market Price: 4,720 gp.</p>
    <a id="spiked-pit-trap-cr-2"></a><p><b>Spiked Pit Trap</b>: CR 2; mechanical; location trigger;
automatic reset; DC 20 Reflex save avoids; 20 ft. deep (2d6, fall);
multiple targets (first target in each of two adjacent 5-ft. squares);
pit spikes (Atk +10 melee, 1d4 spikes per target for 1d4+2 each);
Search DC 18; Disable Device DC 15. Market Price: 1,600 gp.</p>
    <a id="tripping-chain-cr-2"></a><p><b>Tripping Chain</b>: CR 2; mechanical; location trigger;
automatic reset; multiple traps (tripping and melee attack); Atk +15
melee touch (trip), Atk +15 melee (2d4+2, spiked chain); Search DC 15;
Disable Device DC 18. Market Price: 3,800 gp. Note: This trap is really
one CR 1 trap that trips and a second CR 1 trap that attacks with a
spiked chain. If the tripping attack succeeds, a +4 bonus applies to
the spiked chain attack because the opponent is prone.</p>
    <a id="well-camouflaged-pit-trap-cr-2"></a><p><b>Well-Camouflaged Pit Trap</b>: CR 2; mechanical; location
trigger; repair reset; DC 20 Reflex save avoids; 10 ft. deep (1d6,
fall); Search DC 27; Disable Device DC 20. Market Price: 4,400 gp.</p>
    <a id="cr-3-traps"></a><h6>CR 3 Traps</h6>
    <a id="burning-hands-trap-cr-3"></a><p className="initial"><b>Burning Hands Trap</b>: CR 3; magic device;
proximity trigger (alarm); automatic reset; spell effect (burning
hands, 5th-level wizard, 5d4 fire, DC 11 Reflex save half damage);
Search DC 26; Disable Device DC 26. Cost: 2,500 gp, 200 XP.</p>
    <a id="camouflaged-pit-trap-cr-3"></a><p><b>Camouflaged Pit Trap</b>: CR 3; mechanical; location
trigger; manual reset; DC 20 Reflex save avoids; 30 ft. deep (3d6,
fall); multiple targets (first target in each of two adjacent squares);
Search DC 24; Disable Device DC 18. Market Price: 4,800 gp.</p>
    <a id="ceiling-pendulum-cr-3"></a><p><b>Ceiling Pendulum</b>: CR 3; mechanical; timed trigger;
automatic reset; Atk +15 melee (1d12+8/x3, greataxe); Search DC 15;
Disable Device DC 27. Market Price: 14,100 gp.</p>
    <a id="fire-trap-cr-3"></a><p><b>Fire Trap</b>: CR 3; spell; spell trigger; no reset; spell
effect (fire trap, 3rd-level druid, 1d4+3 fire, DC 13 Reflex save half
damage); Search DC 27; Disable Device DC 27. Cost: 85 gp to hire NPC
spellcaster.</p>
    <a id="extended-bane-trap-cr-3"></a><p><b>Extended Bane Trap</b>: CR 3; magic device; proximity
trigger (detect good); automatic reset; spell effect (extended bane,
3rd-level cleric, DC 13 Will save negates); Search DC 27; Disable
Device DC 27. Cost: 3,500 gp, 280 XP.</p>
    <a id="ghoul-touch-trap-cr-3"></a><p><b>Ghoul Touch Trap</b>: CR 3; magic device; touch trigger;
automatic reset; spell effect (ghoul touch, 3rd-level wizard, DC 13
Fortitude save negates); Search DC 27; Disable Device DC 27. Cost:
3,000 gp, 240 XP. </p>
    <a id="hail-of-needles-cr-3"></a><p><b>Hail of Needles</b>: CR 3; mechanical; location trigger;
manual reset; Atk +20 ranged (2d4); Search DC 22; Disable Device DC 22.
Market Price: 5,400 gp.</p>
    <a id="acid-arrow-trap-cr-3"></a><p><b>Acid Arrow Trap</b>: CR 3; magic device; proximity trigger
(alarm); automatic reset; Atk +2 ranged touch; spell effect (acid
arrow, 3rd-level wizard, 2d4 acid/round for 2 rounds); Search DC 27;
Disable Device DC 27. Cost: 3,000 gp, 240 XP.</p>
    <a id="pit-trap-cr-3"></a><p><b>Pit Trap</b>: CR 3; mechanical, location trigger; manual
reset; DC 20 Reflex save avoids; 60 ft. deep (6d6, fall); Search DC 20;
Disable Device DC 20. Market Price: 3,000 gp.</p>
    <a id="poison-arrow-trap-cr-3"></a><p><b>Poisoned Arrow Trap</b>: CR 3; mechanical; touch trigger;
manual reset; lock bypass (Open Lock DC 30); Atk +12 ranged (1d8 plus
poison, arrow); poison (Large monstrous scorpion venom, DC 14 Fortitude
save resists, 1d4 Con/1d4 Con); Search DC 19; Disable Device DC 15.
Market Price: 2,900 gp.</p>
    <a id="spiked-pit-trap-cr-3"></a><p><b>Spiked Pit Trap</b>: CR 3; mechanical; location trigger;
manual reset; DC 20 Reflex save avoids; 20 ft. deep (2d6, fall);
multiple targets (first target in each of two adjacent 5-ft. squares);
pit spikes (Atk +10 melee, 1d4 spikes per target for 1d4+2 each);
Search DC 21; Disable Device DC 20. Market Price: 3,600 gp.</p>
    <a id="stone-blocks-from-ceiling-cr-3"></a><p><b>Stone Blocks from Ceiling</b>: CR 3; mechanical; location
trigger; repair reset; Atk +10 melee (4d6, stone blocks); Search DC 25;
Disable Device DC 20. Market Price: 5,400 gp.</p>
    <a id="cr-4-traps"></a><h6>CR 4 Traps</h6>
    <a id="bestow-curse-trap-cr-4"></a><p className="initial"><b>Bestow Curse Trap</b>: CR 4; magic device;
touch trigger (detect chaos); automatic reset; spell effect (bestow
curse, 5th-level cleric, DC 14 Will save negates); Search DC 28;
Disable Device DC 28. Cost: 8,000 gp, 640 XP.</p>
    <a id="camouflaged-pit-trap-cr-4"></a><p><b>Camouflaged Pit Trap</b>: CR 4; mechanical; location
trigger; manual reset; DC 20 Reflex save avoids; 40 ft. deep (4d6,
fall); multiple targets (first target in each of two adjacent 5-ft.
squares); Search DC 25; Disable Device DC 17. Market Price: 6,800 gp. </p>
    <a id="collapsing-column-cr-4"></a><p><b>Collapsing Column</b>: CR 4; mechanical; touch trigger
(attached); no reset; Atk +15 melee (6d6, stone blocks); Search DC 20;
Disable Device DC 24. Market Price: 8,800 gp.</p>
    <a id="glyph-of-warding-blast-cr-4"></a><p><b>Glyph of Warding (Blast)</b>: CR 4; spell; spell trigger;
no reset; spell effect (glyph of warding [blast], 5th-level cleric, 2d8
acid, DC 14 Reflex save half damage); multiple targets (all targets
within 5 ft.); Search DC 28; Disable Device DC 28. Cost: 350 gp to hire
NPC spellcaster.</p>
    <a id="lightning-bolt-trap-cr-4"></a><p><b>Lightning Bolt Trap</b>: CR 4; magic device; proximity
trigger (alarm); automatic reset; spell effect (lightning bolt,
5th-level wizard, 5d6 electricity, DC 14 Reflex save half damage);
Search DC 28; Disable Device DC 28. Cost: 7,500 gp, 600 XP.</p>
    <a id="pit-trap-cr-4"></a><p><b>Pit Trap</b>: CR 4; mechanical, location trigger; manual
reset; DC 20 Reflex save avoids; 80 ft. deep (8d6, fall); Search DC 20;
Disable Device DC 20. Market Price: 4,000 gp.</p>
    <a id="poisoned-dart-trap-cr-4"></a><p><b>Poisoned Dart Trap</b>: CR 4; mechanical; location trigger;
manual reset; Atk +15 ranged (1d4+4 plus poison, dart); multiple
targets (1 dart per target in a 10-ft.-by-10-ft. area); poison (Small
monstrous centipede poison, DC 10 Fortitude save resists, 1d2 Dex/1d2
Dex); Search DC 21; Disable Device DC 22. Market Price: 12,090 gp.</p>
    <a id="sepia-snake-sigil-trap-cr-4"></a><p><b>Sepia Snake Sigil Trap</b>: CR 4; spell; spell trigger; no
reset; spell effect (sepia snake sigil, 5th-level wizard, DC 14 Reflex
save negates); Search DC 28; Disable Device DC 28. Cost: 650 gp to hire
NPC spellcaster.</p>
    <a id="spiked-pit-trap-cr-4"></a><p><b>Spiked Pit Trap</b>: CR 4; mechanical; location trigger;
automatic reset; DC 20 Reflex save avoids; 60 ft. deep (6d6, fall); pit
spikes (Atk +10 melee, 1d4 spikes per target for 1d4+5 each); Search DC
20; Disable Device DC 20. Market Price: 4,000 gp. </p>
    <a id="wall-scythe-trap-cr-4"></a><p><b>Wall Scythe Trap</b>: CR 4; mechanical; location trigger;
automatic reset; Atk +20 melee (2d4+8/x4, scythe); Search DC 21;
Disable Device DC 18. Market Price: 17,200 gp.</p>
    <a id="water-filled-room-trap-cr-4"></a><p><b>Water-Filled Room Trap</b>: CR 4; mechanical; location
trigger; automatic reset; multiple targets (all targets in a
10-ft.-by-10-ft. room); never miss; onset delay (5 rounds); liquid;
Search DC 17; Disable Device DC 23. Market Price: 11,200 gp.</p>
    <a id="wide-mount-spiked-pit-trap-cr-4"></a><p><b>Wide-Mouth Spiked Pit Trap</b>: CR 4; mechanical; location
trigger; manual reset; DC 20 Reflex save avoids; 20 ft. deep (2d6,
fall); multiple targets (first target in each of two adjacent 5-ft.
squares); pit spikes (Atk +10 melee, 1d4 spikes per target for 1d4+2
each); Search DC 18; Disable Device DC 25. Market Price: 7,200 gp.</p>
    <a id="cr-5-traps"></a><h6>CR 5 Traps</h6>
    <a id="camouflaged-pit-trap-cr-5"></a><p className="initial"><b>Camouflaged Pit Trap</b>: CR 5; mechanical;
location trigger; manual reset; DC 20 Reflex save avoids; 50 ft. deep
(5d6, fall); multiple targets (first target in each of two adjacent
5-ft. squares); Search DC 25; Disable Device DC 17. Market Price: 8,500
gp.</p>
    <a id="doorknob-smeared-with-contact-poison-cr-5"></a><p><b>Doorknob Smeared with Contact Poison</b>: CR 5; mechanical;
touch trigger (attached); manual reset; poison (nitharit, DC 13
Fortitude save resists, 0/3d6 Con); Search DC 25; Disable Device DC 19.
Market Price: 9,650 gp.</p>
    <a id="falling-block-trap"></a><p><b>Falling Block Trap</b>: CR 5; mechanical; location trigger;
manual reset; Atk +15 melee (6d6); multiple targets (can strike all
characters in two adjacent specified squares); Search DC 20; Disable
Device DC 25. Market Price: 15,000 gp.</p>
    <a id="fire-trap-cr-5"></a><p><b>Fire Trap</b>: CR 5; spell; spell trigger; no reset; spell
effect (fire trap, 7th-level wizard, 1d4+7 fire, DC 16 Reflex save half
damage); Search DC 29; Disable Device DC 29. Cost: 305 gp to hire NPC
spellcaster.</p>
    <a id="fireball-trap-cr-5"></a><p><b>Fireball Trap</b>: CR 5; magic device; touch trigger;
automatic reset; spell effect (fireball, 8th-level wizard, 8d6 fire, DC
14 Reflex save half damage); Search DC 28; Disable Device DC 28. Cost:
12,000 gp, 960 XP.</p>
    <a id="flooding-room-trap-cr-5"></a><p><b>Flooding Room Trap</b>: CR 5; mechanical; proximity
trigger; automatic reset; no attack roll necessary (see note below);
Search DC 20; Disable Device DC 25. Note: Room floods in 4 rounds.
Market Price: 17,500 gp.</p>
    <a id="fusillade-of-darts-cr-5"></a><p><b>Fusillade of Darts</b>: CR 5; mechanical; location trigger;
manual reset; Atk +18 ranged (1d4+1, dart); multiple targets (1d8 darts
per target in a 10-ft.-by-10-ft. area); Search DC 19; Disable Device DC
25. Market Price: 18,000 gp.</p>
    <a id="moving-executioner-statue-cr-5"></a><p><b>Moving Executioner Statue</b>: CR 5; mechanical; location
trigger; automatic reset; hidden switch bypass (Search DC 25); Atk +16
melee (1d12+8/x3, greataxe); multiple targets (both arms attack);
Search DC 25; Disable Device DC 18. Market Price: 22,500 gp.</p>
    <a id="phantasmal-killer-trap-cr-5"></a><p><b>Phantasmal Killer Trap</b>: CR 5; magic device; proximity
trigger (alarm covering the entire room); automatic reset; spell effect
(phantasmal killer, 7th-level wizard, DC 16 Will save for disbelief and
DC 16 Fort save for partial effect); Search DC 29; Disable Device DC
29. Cost: 14,000 gp, 1,120 XP.</p>
    <a id="pit-trap-cr-5"></a><p><b>Pit Trap</b>: CR 5; mechanical, location trigger; manual
reset; DC 20 Reflex save avoids; 100 ft. deep (10d6, fall); Search DC
20; Disable Device DC 20. Market Price: 5,000 gp.</p>
    <a id="poison-wall-spikes-cr-5"></a><p><b>Poison Wall Spikes</b>: CR 5; mechanical; location trigger;
manual reset; Atk +16 melee (1d8+4 plus poison, spike); multiple
targets (closest target in each of two adjacent 5-ft. squares); poison
(Medium monstrous spider venom, DC 12 Fortitude save resists, 1d4
Str/1d4 Str); Search DC 17; Disable Device DC 21. Market Price: 12,650
gp.</p>
    <a id="spiked-pit-trap-cr-5"></a><p><b>Spiked Pit Trap</b>: CR 5; mechanical; location trigger;
manual reset; DC 25 Reflex save avoids; 40 ft. deep (4d6, fall);
multiple targets (first target in each of two adjacent 5-ft. squares);
pit spikes (Atk +10 melee, 1d4 spikes per target for 1d4+4 each);
Search DC 21; Disable Device DC 20. Market Price: 13,500 gp.</p>
    <a id="spike-pit-trap-80-ft-deep-cr-5"></a><p><b>Spiked Pit Trap (80 Ft. Deep)</b>: CR 5; mechanical;
location trigger, manual reset; DC 20 Reflex save avoids; 80 ft. deep
(8d6, fall), pit spikes (Atk +10 melee, 1d4 spikes for 1d4+5 each);
Search DC 20; Disable Device DC 20. Market Price: 5,000 gp. </p>
    <a id="ungol-dust-vapor-trap-cr-5"></a><p><b>Ungol Dust Vapor Trap</b>: CR 5; mechanical; location
trigger; manual reset; gas; multiple targets (all targets in a
10-ft.-by-10-ft. room); never miss; onset delay (2 rounds); poison
(ungol dust, DC 15 Fortitude save resists, 1 Cha/1d6 Cha plus 1 Cha
drain); Search DC 20; Disable Device DC 16. Market Price: 9,000 gp.</p>
    <a id="cr-6-traps"></a><h6>CR 6 Traps </h6>
    <a id="built-to-collapse-wall-cr-6"></a><p className="initial"><b>Built-to-Collapse Wall</b>: CR 6;
mechanical; proximity trigger; no reset; Atk +20 melee (8d6, stone
blocks); multiple targets (all targets in a 10-ft.-by-10-ft. area);
Search DC 14; Disable Device DC 16. Market Price: 15,000 gp.</p>
    <a id="compacting-room-cr-6"></a><p><b>Compacting Room</b>: CR 6; mechanical; timed trigger;
automatic reset; hidden switch bypass (Search DC 25); walls move
together (12d6, crush); multiple targets (all targets in a 10-ft.-by-
10-ft. room); never miss; onset delay (4 rounds); Search DC 20; Disable
Device DC 22. Market Price: 25,200 gp.</p>
    <a id="flame-strike-trap-cr-6"></a><p><b>Flame Strike Trap</b>: CR 6; magic device; proximity
trigger (detect magic); automatic reset; spell effect (flame strike,
9th-level cleric, 9d6 fire, DC 17 Reflex save half damage); Search DC
30; Disable Device DC 30. Cost: 22,750 gp, 1,820 XP.</p>
    <a id="fusillade-of-spears-cr-6"></a><p><b>Fusillade of Spears</b>: CR 6; mechanical; proximity
trigger; repair reset; Atk +21 ranged (1d8, spear); multiple targets
(1d6 spears per target in a 10 ft.-by-10-ft. area); Search DC 26;
Disable Device DC 20. Market Price: 31,200 gp.</p>
    <a id="glyph-of-warding-blast-cr-6"></a><p><b>Glyph of Warding (Blast)</b>: CR 6; spell; spell trigger;
no reset; spell effect (glyph of warding [blast], 16th-level cleric,
8d8 sonic, DC 14 Reflex save half damage); multiple targets (all
targets within 5 ft.); Search DC 28; Disable Device DC 28. Cost: 680 gp
to hire NPC spellcaster.</p>
    <a id="lightning-bolt-trap-cr-6"></a><p><b>Lightning Bolt Trap</b>: CR 6; magic device; proximity
trigger (alarm); automatic reset; spell effect (lightning bolt,
10th-level wizard, 10d6 electricity, DC 14 Reflex save half damage);
Search DC 28; Disable Device DC 28. Cost: 15,000 gp, 1,200 XP.</p>
    <a id="spiked-blocks-from-ceiling-cr-6"></a><p><b>Spiked Blocks from Ceiling</b>: CR 6; mechanical; location
trigger; repair reset; Atk +20 melee (6d6, spikes); multiple targets
(all targets in a 10-ft.-by-10-ft. area); Search DC 24; Disable Device
DC 20. Market Price: 21,600 gp.</p>
    <a id="spiked-pit-trap-100-ft-deep-cr-6"></a><p><b>Spiked Pit Trap (100 Ft. Deep)</b>: CR 6; mechanical;
location trigger, manual reset; DC 20 Reflex save avoids; 100 ft. deep
(10d6, fall); pit spikes (Atk +10 melee, 1d4 spikes per target for
1d4+5 each); Search DC 20; Disable Device DC 20. Market Price: 6,000 gp.</p>
    <a id="whirling-poison-blades-cr-6"></a><p><b>Whirling Poison Blades</b>: CR 6; mechanical; timed
trigger; automatic reset; hidden lock bypass (Search DC 25, Open Lock
DC 30); Atk +10 melee (1d4+4/19&#8211;20 plus poison, dagger); poison (purple
worm poison, DC 24 Fortitude save resists, 1d6 Str/2d6 Str); multiple
targets (one target in each of three preselected 5-ft. squares); Search
DC 20; Disable Device DC 20. Market Price: 30,200 gp.</p>
    <a id="wide-mouth-pit-trap-cr-6"></a><p><b>Wide-Mouth Pit Trap</b>: CR 6; mechanical; location
trigger, manual reset; DC 25 Reflex save avoids; 40 ft. deep (4d6,
fall); multiple targets (all targets within a 10-ft.-by-10-ft. area);
Search DC 26; Disable Device DC 25. Market Price: 28,200 gp.</p>
    <a id="wyvern-arrow-trap-cr-6"></a><p><b>Wyvern Arrow Trap</b>: CR 6; mechanical; proximity trigger;
manual reset; Atk +14 ranged (1d8 plus poison, arrow); poison (wyvern
poison, DC 17 Fortitude save resists, 2d6 Con/2d6 Con); Search DC 20;
Disable Device DC 16. Market Price: 17,400 gp. </p>
    <a id="cr-7-traps"></a><h6>CR 7 Traps</h6>
    <a id="acid-fog-trap-cr-7"></a><p className="initial"><b>Acid Fog Trap</b>: CR 7; magic device;
proximity trigger (alarm); automatic reset; spell effect (acid fog,
11th-level wizard, 2d6/round acid for 11 rounds); Search DC 31; Disable
Device DC 31. Cost: 33,000 gp, 2,640 XP.</p>
    <a id="blade-barrier-trap-cr-7"></a><p><b>Blade Barrier Trap</b>: CR 7; magic device; proximity
trigger (alarm); automatic reset; spell effect (blade barrier,
11th-level cleric, 11d6 slashing, DC 19 Reflex save half damage);
Search DC 31; Disable Device DC 31. Cost: 33,000 gp, 2,640 XP.</p>
    <a id="burnt-othur-vapor-trap-cr-7"></a><p><b>Burnt Othur Vapor Trap</b>: CR 7; mechanical; location
trigger; repair reset; gas; multiple targets (all targets in a
10-ft.-by-10-ft. room); never miss; onset delay (3 rounds); poison
(burnt othur fumes, DC 18 Fortitude save resists, 1 Con drain/3d6 Con);
Search DC 21; Disable Device DC 21. Market Price: 17,500 gp.</p>
    <a id="chain-lightning-trap-cr-7"></a><p><b>Chain Lightning Trap</b>: CR 7; magic device; proximity
trigger (alarm); automatic reset; spell effect (chain lightning,
11th-level wizard, 11d6 electricity to target nearest center of trigger
area plus 5d6 electricity to each of up to eleven secondary targets, DC
19 Reflex save half damage); Search DC 31; Disable Device DC 31. Cost:
33,000 gp, 2,640 XP. </p>
    <a id="black-tentacles-trap-cr-7"></a><p><b>Black Tentacles Trap</b>: CR 7; magic device; proximity
trigger (alarm); no reset; spell effect (black tentacles, 7th-level
wizard, 1d4+7 tentacles, Atk +7 melee [1d6+4, tentacle]); multiple
targets (up to six tentacles per target in each of two adjacent 5-ft.
squares); Search DC 29; Disable Device DC 29. Cost: 1,400 gp, 112 XP.</p>
    <a id="fusillade-of-greenblood-oil-darts-cr-7"></a><p><b>Fusillade of Greenblood Oil Darts</b>: CR 7; mechanical;
location trigger; manual reset; Atk +18 ranged (1d4+1 plus poison,
dart); poison (greenblood oil, DC 13 Fortitude save resists, 1 Con/ 1d2
Con); multiple targets (1d8 darts per target in a 10-ft.-by-10-ft.
area); Search DC 25; Disable Device DC 25. Market Price: 33,000 gp.</p>
    <a id="lock-covered-in-dragon-bile"></a><p><b>Lock Covered in Dragon Bile</b>: CR 7; mechanical; touch
trigger (attached); no reset; poison (dragon bile, DC 26 Fortitude save
resists, 3d6 Str/0); Search DC 27; Disable Device DC 16. Market Price:
11,300 gp.</p>
    <a id="summon-monster-vi-trap-cr-7"></a><p><b>Summon Monster VI Trap</b>: CR 7; magic device; proximity
trigger (alarm); no reset; spell effect (summon monster VI, 11th-level
wizard), Search DC 31; Disable Device DC 31. Cost: 3,300 gp, 264 XP.</p>
    <a id="water-filled-room-cr-7"></a><p><b>Water-Filled Room</b>: CR 7; mechanical; location trigger;
manual reset; multiple targets (all targets in a 10-ft.-by-10-ft.
room); never miss; onset delay (3 rounds); water; Search DC 20; Disable
Device DC 25. Market Price: 21,000 gp. </p>
    <a id="well-camouflaged-pit-trap-cr-7"></a><p><b>Well-Camouflaged Pit Trap</b>: CR 7; mechanical; location
trigger; repair reset; DC 25 Reflex save avoids; 70 ft. deep (7d6,
fall); multiple targets (first target in each of two adjacent 5-ft.
squares); Search DC 27; Disable Device DC 18. Market Price: 24,500 gp. </p>
    <a id="cr-8-traps"></a><h6>CR 8 Traps</h6>
    <a id="deathblade-wall-scythe-cr-8"></a><p className="initial"><b>Deathblade Wall Scythe</b>: CR 8;
mechanical; touch trigger; manual reset; Atk +16 melee (2d4+8 plus
poison, scythe); poison (deathblade, DC 20 Fortitude save resists, 1d6
Con/2d6 Con); Search DC 24; Disable Device DC 19. Market Price: 31,400
gp.</p>
    <a id="destruction-trap-cr-8"></a><p><b>Destruction Trap</b>: CR 8; magic device; touch trigger
(alarm); automatic reset; spell effect (destruction, 13th-level cleric,
DC 20 Fortitude save for 10d6 damage); Search DC 32; Disable Device DC
32. Cost: 45,500 gp, 3,640 XP.</p>
    <a id="earthquake-trap-cr-8"></a><p><b>Earthquake Trap</b>: CR 8; magic device; proximity trigger
(alarm); automatic reset; spell effect (earthquake, 13th-level cleric,
65-ft. radius, DC 15 or 20 Reflex save, depending on terrain); Search
DC 32; Disable Device DC 32. Cost: 45,500 gp, 3,640 XP.</p>
    <a id="insanity-mist-vapor-trap-cr-8"></a><p><b>Insanity Mist Vapor Trap</b>: CR 8; mechanical; location
trigger; repair reset; gas; never miss; onset delay (1 round); poison
(insanity mist, DC 15 Fortitude save resists, 1d4 Wis/2d6 Wis);
multiple targets (all targets in a 10-ft.-by-10-ft. room); Search DC
25; Disable Device DC 20. Market Price: 23,900 gp.</p>
    <a id="acid-arrow-trap-cr-8"></a><p><b>Acid Arrow Trap</b>: CR 8; magic device; visual trigger
(true seeing); automatic reset; multiple traps (two simultaneous acid
arrow traps); Atk +9 ranged touch and +9 ranged touch; spell effect
(acid arrow, 18th-level wizard, 2d4 acid damage for 7 rounds); Search
DC 27; Disable Device DC 27. Cost: 83,500 gp, 4,680 XP. Note: This trap
is really two CR 6 acid arrow traps that fire simultaneously, using the
same trigger and reset.</p>
    <a id="power-word-stun-trap-cr-8"></a><p><b>Power Word Stun Trap</b>: CR 8; magic device; touch
trigger; no reset; spell effect (power word stun, 13th-level wizard),
Search DC 32; Disable Device DC 32. Cost: 4,550 gp, 364 XP.</p>
    <a id="prismatic-spray-trap-cr-8"></a><p><b>Prismatic Spray Trap</b>: CR 8; magic device; proximity
trigger (alarm); automatic reset; spell effect (prismatic spray,
13th-level wizard, DC 20 Reflex, Fortitude, or Will save, depending on
effect); Search DC 32; Disable Device DC 32. Cost: 45,500 gp, 3,640 XP.</p>
    <a id="reverse-gravity-trap-cr-8"></a><p><b>Reverse Gravity Trap</b>: CR 8; magic device; proximity
trigger (alarm, 10-ft. area); automatic reset; spell effect (reverse
gravity, 13th-level wizard, 6d6 fall [upon hitting the ceiling of the
60-ft.- high room], then 6d6 fall [upon falling 60 ft. to the floor
when the spell ends], DC 20 Reflex save avoids damage); Search DC 32;
Disable Device DC 32. Cost: 45,500 gp, 3,640 XP.</p>
    <a id="well-camouflaged-pit-trap-cr-8"></a><p><b>Well-Camouflaged Pit Trap</b>: CR 8; mechanical; location
trigger; repair reset; DC 20 Reflex save avoids; 100 ft. deep (10d6,
fall); Search DC 27; Disable Device DC 18. Market Price: 16,000 gp.</p>
    <a id="word-of-chaos-trap-cr-8"></a><p><b>Word of Chaos Trap</b>: CR 8; magic device; proximity
trigger (detect law); automatic reset; spell effect (word of chaos,
13th-level cleric); Search DC 32; Disable Device DC 32. Cost: 46,000
gp, 3,680 XP.</p>
    <a id="cr-9-traps"></a><h6>CR 9 Traps</h6>
    <a id="drawer-handle-smeared-with-contact-poison-cr-9"></a><p className="initial"><b>Drawer Handle Smeared with Contact Poison</b>:
CR 9; mechanical; touch trigger (attached); manual reset; poison (black
lotus extract, DC 20 Fortitude save resists, 3d6 Con/3d6 Con); Search
DC 18; Disable Device DC 26. Market Price: 21,600 gp. </p>
    <a id="dropping-ceiling-cr-9"></a><p><b>Dropping Ceiling</b>: CR 9; mechanical; location trigger;
repair reset; ceiling moves down (12d6, crush); multiple targets (all
targets in a 10-ft.-by-10-ft. room); never miss; onset delay (1 round);
Search DC 20; Disable Device DC 16. Market Price: 12,600 gp. </p>
    <a id="incendiary-trap-cr-9"></a><p><b>Incendiary Cloud Trap</b>: CR 9; magic device; proximity
trigger (alarm); automatic reset; spell effect (incendiary cloud,
15th-level wizard, 4d6/round for 15 rounds, DC 22 Reflex save half
damage); Search DC 33; Disable Device DC 33. Cost: 60,000 gp, 4,800 XP.
    </p>
    <a id="wide-mouth-pit-trap-cr-9"></a><p><b>Wide-Mouth Pit Trap</b>: CR 9; mechanical; location
trigger; manual reset; DC 25 Reflex save avoids; 100 ft. deep (10d6,
fall); multiple targets (all targets within a 10-ft.-by-10-ft. area);
Search DC 25; Disable Device DC 25. Market Price: 40,500 gp. </p>
    <a id="wide-mouth-spiked-pit-with-poisoned-spikes-cr-9"></a><p><b>Wide-Mouth Spiked Pit with Poisoned Spikes</b>: CR 9;
mechanical; location trigger; manual reset; hidden lock bypass (Search
DC 25, Open Lock DC 30); DC 20 Reflex save avoids; 70 ft. deep (7d6,
fall); multiple targets (all targets within a 10-ft.-by-10-ft. area);
pit spikes (Atk +10 melee, 1d4 spikes per target for 1d4+5 plus poison
each); poison (giant wasp poison, DC 14 Fortitude save resists, 1d6
Dex/1d6 Dex); Search DC 20; Disable Device DC 20. Market Price: 11,910
gp.</p>
    <a id="cr-10-traps"></a><h6>CR 10 Traps</h6>
    <a id="crushing-room-cr-10"></a><p className="initial"><b>Crushing Room</b>: CR 10; mechanical;
location trigger; automatic reset; walls move together (16d6, crush);
multiple targets (all targets in a 10-ft.-by-10-ft. room); never miss;
onset delay (2 rounds); Search DC 22; Disable Device DC 20. Market
Price: 29,000 gp.</p>
    <a id="crushing-wall-trap-cr-10"></a><p><b>Crushing Wall Trap</b>: CR 10; mechanical; location
trigger; automatic reset; no attack roll required (18d6, crush); Search
DC 20; Disable Device DC 25. Market Price: 25,000 gp. </p>
    <a id="energy-drain-trap-cr-10"></a><p><b>Energy Drain Trap</b>: CR 10; magic device; visual trigger
(true seeing); automatic reset; Atk +8 ranged touch; spell effect
(energy drain, 17th-level wizard, 2d4 negative levels for 24 hours, DC
23 Fortitude save negates); Search DC 34; Disable Device DC 34. Cost:
124,000 gp, 7,920 XP.</p>
    <a id="forcecage-and-summon-monster-vii-trap-cr-10"></a><p><b>Forcecage and Summon Monster VII trap</b>: CR 10; magic
device; proximity trigger (alarm); automatic reset; multiple traps (one
forcecage trap and one summon monster VII trap that summons a
hamatula); spell effect (forcecage, 13th-level wizard), spell effect
(summon monster VII, 13th-level wizard, hamatula); Search DC 32;
Disable Device DC 32. Cost: 241,000 gp, 7,280 XP. Note: This trap is
really one CR 8 trap that creates a forcecage and a second CR 8 trap
that summons a hamatula in the same area. If both succeed, the hamatula
appears inside the forcecage. These effects are independent of each
other.</p>
    <a id="poisoned-spiked-pit-trap-cr-10"></a><p><b>Poisoned Spiked Pit Trap</b>: CR 10; mechanical; location
trigger; manual reset; hidden lock bypass (Search DC 25, Open Lock DC
30); DC 20 Reflex save avoids; 50 ft. deep (5d6, fall); multiple
targets (first target in each of two adjacent 5-ft. squares); pit
spikes (Atk +10 melee, 1d4 spikes per target for 1d4+5 plus poison
each); poison (purple worm poison, DC 24 Fortitude save resists, 1d6
Str/2d6 Str); Search DC 16; Disable Device DC 25. Market Price: 19,700
gp. </p>
    <a id="wail-of-the-banshee-trap-cr-10"></a><p><b>Wail of the Banshee Trap</b>: CR 10; magic device;
proximity trigger (alarm); automatic reset; spell effect (wail of the
banshee, 17th-level wizard, DC 23 Fortitude save negates); multiple
targets (up to 17 creatures); Search DC 34; Disable Device DC 34. Cost:
76,500 gp, 6,120 XP.</p>
    <a id="designing-a-trap"></a><h5>DESIGNING A TRAP</h5>
    <a id="designing-mechanical-traps"></a><p className="initial"><b>Mechanical Traps</b>: Simply select the
elements you want the trap to have and add up the adjustments to the
trap&#8217;s Challenge Rating that those elements require (see Table: CR
Modifiers for Mechanical Traps) to arrive at the trap&#8217;s final CR. From
the CR you can derive the DC of the <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a> (trapmaking) checks a
character must make to construct the trap.</p>
    <a id="designing-magic-traps"></a><p><b>Magic Traps</b>: As with mechanical traps, you don&#8217;t have
to do anything other than decide what elements you want and then
determine the CR of the resulting trap (see Table: CR Modifiers for
Magic Traps). If a player character wants to design and construct a
magic trap, he must have the <a href="featsAll.html#craft-wondrous-item" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft Wondrous Item</a> feat. In
addition, he
must be able to cast the spell or spells that the trap requires&#8212;or,
failing that, he must be able to hire an NPC to cast the spells for him.</p>
    <a id="challenge-rating-of-a-trap"></a><h6>Challenge Rating of a Trap</h6>
    <p className="initial">To calculate the Challenge Rating of a trap,
add all the CR modifiers (see the tables below) to the base CR for the
trap type.</p>
    <a id="challenge-rating-of-mechanical-trap"></a><p><b>Mechanical Trap</b>: The base CR for a mechanical trap is
0. If your final CR is 0 or lower, add features until you get a CR of 1
or higher.</p>
    <a id="challenge-rating-of-magic-trap"></a><p><b>Magic Trap</b>: For a spell trap or magic device trap, the
base CR is 1. The highest-level spell used modifies the CR (see Table:
CR Modifiers for Magic Traps).</p>
    <a id="average-damage"></a><p><b>Average Damage</b>: If a trap (either mechanical or magic)
does hit point damage, calculate the average damage for a successful
hit and round that value to the nearest multiple of 7. Use this value
to adjust the Challenge Rating of the trap, as indicated on the tables
below. Damage from poisons and pit spikes does not count toward this
value, but damage from a high strength rating and extra damage from
multiple attacks does.</p>
    <p>For a magic trap, only one modifier applies to the CR&#8212;either
the level of the highest-level spell used in the trap, or the average
damage figure, whichever is larger.</p>
    <a id="multiple-traps"></a><p><b>Multiple Traps</b>: If a trap is really two or more
connected traps that affect approximately the same area, determine the
CR of each one separately.</p>
    <a id="multiple-dependent-traps"></a><p><i>Multiple Dependent Traps</i>: If one trap depends on the
success of the other (that is, you can avoid the second trap altogether
by not falling victim to the first), they must be treated as separate
traps.</p>
    <a id="multiple-independent-traps"></a><p><i>Multiple Independent Traps</i>: If two or more traps act
independently (that is, none depends on the success of another to
activate), use their CRs to determine their combined Encounter Level as
though they were monsters. The resulting Encounter Level is the CR for
the combined traps.</p>
    <a id="table-cr-modifiers-for-mechanical-traps"></a><p><b>Table: CR MODIFIERS FOR MECHANICAL TRAPS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th colSpan="2">Feature</th>
          <th colSpan="2">CR Modifier</th>
        </tr>
        <tr className="odd-row">
          <td colSpan="4"><i><a href="skillsAll.html#search" style={{
                "color": "rgb(87, 158, 182)"
              }}>Search</a> DC</i></td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">15 or lower</td>
          <td colSpan="2">&#8211;1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">25&#8211;29</td>
          <td colSpan="2">+1</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">30 or higher</td>
          <td colSpan="2">+2</td>
        </tr>
        <tr className="odd-row">
          <td colSpan="4"><i><a href="skillsAll.html#disable-device" style={{
                "color": "rgb(87, 158, 182)"
              }}>Disable Device</a> DC</i></td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">15 or lower</td>
          <td colSpan="2">&#8211;1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">25&#8211;29</td>
          <td colSpan="2">+1</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">30 or higher</td>
          <td colSpan="2">+2</td>
        </tr>
        <tr className="odd-row">
          <td colSpan="4"><i>Reflex Save DC (Pit or Other
Save-Dependent Trap)</i></td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">15 or lower</td>
          <td colSpan="2">&#8211;1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">16&#8211;24</td>
          <td colSpan="2">&#8212;</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">25&#8211;29</td>
          <td colSpan="2">+1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">30 or higher</td>
          <td colSpan="2">+2</td>
        </tr>
        <tr>
          <td colSpan="4"><i>Attack Bonus (Melee or Ranged Attack
Trap)</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">+0 or lower</td>
          <td colSpan="2">&#8211;2</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">+1 to +5</td>
          <td colSpan="2">&#8211;1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">+6 to +14</td>
          <td colSpan="2">&#8212;</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">+15 to +19</td>
          <td colSpan="2">+1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">+20 to +24</td>
          <td colSpan="2">+2</td>
        </tr>
        <tr>
          <td colSpan="4"><i>Damage/Effect</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">Average damage</td>
          <td colSpan="2">+1/7 points*</td>
        </tr>
        <tr>
          <td colSpan="4"><i>Miscellaneous Features</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">Alchemical device</td>
          <td colSpan="2">Level of spell mimicked</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">Liquid</td>
          <td colSpan="2">+5</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">Multiple target</td>
          <td colSpan="2">+1 (or 0 if never miss)</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">Onset delay 1 round</td>
          <td colSpan="2">+3</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">Onset delay 2 rounds</td>
          <td colSpan="2">+2</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">Onset delay 3 rounds</td>
          <td colSpan="2">+1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">Onset delay 4+ rounds</td>
          <td colSpan="2">&#8211;1</td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">Poison</td>
          <td colSpan="2">CR of poison (see below)</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2" style={{
            "width": "40%"
          }}>Black adder venom</td>
          <td style={{
            "width": "10%"
          }}>+1</td>
          <td className="indent" style={{
            "width": "40%"
          }}>Large scorpion venom</td>
          <td style={{
            "width": "10%"
          }}>+3</td>
        </tr>
        <tr>
          <td className="indent2">Black lotus extract</td>
          <td>+8</td>
          <td className="indent">Malyss root paste</td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Bloodroot</td>
          <td>+1</td>
          <td className="indent">Medium spider venom</td>
          <td>+2</td>
        </tr>
        <tr>
          <td className="indent2">Blue whinnis</td>
          <td>+1</td>
          <td className="indent">Nitharit</td>
          <td>+4</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Burnt othur fumes</td>
          <td>+6</td>
          <td className="indent">Purple worm poison</td>
          <td>+4</td>
        </tr>
        <tr>
          <td className="indent2">Deathblade</td>
          <td>+5</td>
          <td className="indent">Sassone leaf residue</td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Dragon bile</td>
          <td>+6</td>
          <td className="indent">Shadow essence</td>
          <td>+3</td>
        </tr>
        <tr>
          <td className="indent2">Giant wasp poison</td>
          <td>+3</td>
          <td className="indent">Small centipede poison</td>
          <td>+1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Greenblood oil</td>
          <td>+1</td>
          <td className="indent">Terinav root</td>
          <td>+5</td>
        </tr>
        <tr>
          <td className="indent2">Insanity mist</td>
          <td>+4</td>
          <td className="indent">Ungol dust</td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Wyvern poison</td>
          <td>+5</td>
          <td className="indent">
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent" colSpan="2">Pit spikes</td>
          <td colSpan="2">+1</td>
        </tr>
        <tr className="odd-row">
          <td className="indent" colSpan="2">Touch attack</td>
          <td colSpan="2">+1</td>
        </tr>
        <tr>
          <td colSpan="4">* <i>Rounded to the nearest multiple of 7
(round up for an average that lies exactly between two numbers). </i></td>
        </tr>
      </tbody>
    </table>
    <a id="table-cr-modifiers-for-magic-traps"></a><p><b>Table: CR MODIFIERS FOR MAGIC TRAPS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "50%"
          }}>Feature</th>
          <th style={{
            "width": "50%"
          }}>CR Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Highest-level spell</td>
          <td>+ Spell level OR +1 per 7 points of average damage per
round*</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>See the note following Table: CR
Modifiers for Mechanical Traps.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="mechanical-trap-cost"></a><h6>Mechanical Trap Cost</h6>
    <p className="initial">The base cost of a mechanical trap is 1,000
gp. Apply all the modifiers from Table: Cost Modifiers for Mechanical
Traps for the various features you&#8217;ve added to the trap to get the
modified base cost.</p>
    <p>The final cost is equal to (modified base cost x Challenge
Rating) + extra costs. The minimum cost for a mechanical trap is (CR x
100) gp.</p>
    <p>After you&#8217;ve multiplied the modified base cost by the
Challenge Rating, add the price of any alchemical items or poison you
incorporated into the trap. If the trap uses one of these elements and
has an automatic reset, multiply the poison or alchemical item cost by
20 to provide an adequate supply of doses.</p>
    <a id="multiple-traps-costs"></a><p><b>Multiple Traps</b>: If a trap is really two or more
connected traps, determine the final cost of each separately, then add
those values together. This holds for both multiple dependent and
multiple independent traps (see the previous section).</p>
    <a id="cost-modifiers-for-mechanical-traps"></a><p><b>Table: COST MODIFIERS FOR MECHANICAL TRAPS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Feature</th>
          <th>Cost Modifier</th>
        </tr>
        <tr className="odd-row">
          <td colSpan="2"><i>Trigger Type</i></td>
        </tr>
        <tr>
          <td className="indent">Location</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Proximity</td>
          <td>+1,000 gp</td>
        </tr>
        <tr>
          <td className="indent">Touch</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Touch (attached)</td>
          <td>&#8211;100 gp</td>
        </tr>
        <tr>
          <td className="indent">Timed</td>
          <td>+1,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td colSpan="2"><i>Reset Type</i></td>
        </tr>
        <tr>
          <td className="indent">No reset</td>
          <td>&#8211;500 gp</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Repair</td>
          <td>&#8211;200 gp</td>
        </tr>
        <tr>
          <td className="indent">Manual</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Automatic</td>
          <td>+500 gp (or 0 if trap has timed trigger)</td>
        </tr>
        <tr>
          <td colSpan="2"><i>Bypass Type</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Lock</td>
          <td>+100 gp (<a href="skillsAll.html#open-lock" style={{
              "color": "rgb(87, 158, 182)"
            }}>Open Lock</a> DC 30)</td>
        </tr>
        <tr>
          <td className="indent">Hidden switch</td>
          <td>+200 gp (Search DC 25)</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Hidden lock</td>
          <td>+300 gp (Open Lock DC 30, Search DC 25)</td>
        </tr>
        <tr>
          <td colSpan="2"><i><a href="skillsAll.html#search" style={{
                "color": "rgb(87, 158, 182)"
              }}>Search</a> DC</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent">19 or lower</td>
          <td>&#8211;100 gp x (20 &#8211; DC)</td>
        </tr>
        <tr>
          <td className="indent">20</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">21 or higher</td>
          <td>+200 gp x (DC &#8211; 20)</td>
        </tr>
        <tr>
          <td colSpan="2"><i><a href="skillsAll.html#disable-device" style={{
                "color": "rgb(87, 158, 182)"
              }}>Disable Device</a> DC</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent">19 or lower</td>
          <td>&#8211;100 gp x (20 &#8211; DC)</td>
        </tr>
        <tr>
          <td className="indent">20</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">21 or higher</td>
          <td>+200 gp x (DC &#8211; 20)</td>
        </tr>
        <tr>
          <td colSpan="2"><i>Reflex Save DC (Pit or Other
Save-Dependent Trap)</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent">19 or lower</td>
          <td>&#8211;100 gp x (20 &#8211; DC)</td>
        </tr>
        <tr>
          <td className="indent">20</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">21 or higher</td>
          <td>+300 gp x (DC &#8211; 20)</td>
        </tr>
        <tr>
          <td colSpan="2"><i>Attack Bonus (Melee or Ranged Attack
Trap)</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent">+9 or lower</td>
          <td>&#8211;100 gp x (10 &#8211; bonus)</td>
        </tr>
        <tr>
          <td className="indent">+10</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">+11 or higher</td>
          <td>+200 gp x (bonus &#8211; 10)</td>
        </tr>
        <tr>
          <td colSpan="2"><i>Damage Bonus</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent">High strength rating (ranged attack trap)</td>
          <td>+100 gp x bonus (max +4)</td>
        </tr>
        <tr>
          <td className="indent">High Strength bonus (melee attack trap)</td>
          <td>+100 gp x bonus (max +8)</td>
        </tr>
        <tr className="odd-row">
          <td colSpan="2"><i>Miscellaneous Features</i></td>
        </tr>
        <tr>
          <td className="indent">Never miss</td>
          <td>+1,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Poison</td>
          <td>Cost of poison*</td>
        </tr>
        <tr>
          <td className="indent">Alchemical item</td>
          <td>Cost of item*</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Multiply cost by 20 if trap features
automatic reset.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="magic-device-trap-cost"></a><h6>Magic Device Trap Cost</h6>
    <p className="initial">Building a magic device trap involves the
expenditure of experience points as well as gold pieces, and requires
the services of a spellcaster. Table: Cost Modifiers for Magic Device
Traps summarizes the cost information for magic device traps. If the
trap uses more than one spell (for instance, a sound or visual trigger
spell in addition to the main spell effect), the builder must pay for
them all (except alarm, which is free unless it must be cast by an NPC;
see below).</p>
    <p>The costs derived from Table: Cost Modifiers for Magic Device
Traps assume that the builder is casting the necessary spells himself
(or perhaps some other PC is providing the spells for free). If an NPC
spellcaster must be hired to cast them those costs must be factored in
as well.</p>
    <p>A magic device trap takes one day to construct per 500 gp of
its cost.</p>
    <a id="table-cost-modifiers-for-magic-device-traps"></a><p><b>Table: COST MODIFIERS FOR MAGIC DEVICE TRAPS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>Feature</th>
          <th>Cost Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Alarm spell used in trigger</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td colSpan="2"><i>One-Shot Trap</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Each spell used in trap</td>
          <td>+50 gp x caster level x spell level,
+4 XP x caster level x spell level</td>
        </tr>
        <tr>
          <td className="indent">Material components</td>
          <td>+ Cost of all material components</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">XP components</td>
          <td>+ Total of XP components x 5 gp</td>
        </tr>
        <tr>
          <td colSpan="2"><i>Automatic Reset Trap</i></td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Each spell used in trap</td>
          <td>+500 gp x caster level x spell level,
+40 XP x caster level x spell level</td>
        </tr>
        <tr>
          <td className="indent">Material components</td>
          <td>+ Cost of all material components x 100 gp</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">XP components</td>
          <td>+ Total of XP components x 500 gp</td>
        </tr>
      </tbody>
    </table>
    <a id="spell-trap-cost"></a><h6>Spell Trap Cost</h6>
    <p className="initial">A spell trap has a cost only if the builder
must hire an NPC spellcaster to cast it.</p>
    <a id="craft-dcs-for-mechanical-traps"></a><h6>Craft DCs for Mechanical Traps</h6>
    <p className="initial">Once you know the Challenge Rating of a trap
determine the <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a> (trapmaking) DC by
referring to the table and the
modifiers given below.</p>
    <a id="table-craft-dcs-for-mechanical-traps"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "450px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "60%"
          }}>Trap CR</th>
          <th style={{
            "width": "40%"
          }}>Base Craft
(Trapmaking) DC</th>
        </tr>
        <tr className="odd-row">
          <td>1&#8211;3</td>
          <td>20</td>
        </tr>
        <tr>
          <td>4&#8211;6</td>
          <td>25</td>
        </tr>
        <tr className="odd-row">
          <td>7&#8211;10</td>
          <td>30</td>
        </tr>
      </tbody>
    </table>
    <a id="table-craft-dc-modifiers-for-mechanical-traps"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "450px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "60%"
          }}>Additional Components</th>
          <th style={{
            "width": "40%"
          }}>Modifier to Craft
(Trapmaking) DC</th>
        </tr>
        <tr className="odd-row">
          <td>Proximity trigger</td>
          <td>+5</td>
        </tr>
        <tr>
          <td className="last-row">Automatic reset</td>
          <td className="last-row">+5</td>
        </tr>
      </tbody>
    </table>
    <p><b>Making the Checks</b>: To determine how much progress a
character makes on building a trap each week, that character makes a
Craft (trapmaking) check. See the Craft skill description for details
on Craft checks and the circumstances that can affect them.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      